@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700&display=swap');

:root {
  
	/* ----- Default Color Code Schemes ----- */
	--dark: #222;
	--basic: #fff;
	--light: #eee;
	--primary: #4f81bc;
	--secondary: #bc8a4f;
	--default: #355c8a;
	--mindcolor: #787878;
	--danger: red;
	--success: green;
  
	/* ----- Default Fonts Typography ----- */
	--smallset-size: 10px;
	--smaller-size: 12px;
	--small-size: 14px;
	--default-size: 16px;
	--large-size: 18px;
	--larger-size: 20px;
	--largest-size: 28px;
	--subtitle: 36px;
	--section-heading: 44px;
	--title-heading: 64px;
	--main-heading: 100px;
  }
  
* {
	color: var(--default);
	font-family: 'Roboto', sans-serif;
	font-size: var(--default-size);
	margin: 0;
	padding: 0;
}

html {
	scroll-behavior: smooth;
}

a {
    text-decoration: none;
	transition: all .3s ease-in-out;
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
	max-width: 1920px;
	margin: 0 auto;
}

h1 {
	color: var(--basic);
	font-family: 'Open Sans', sans-serif;
	font-size: var(--main-heading);
	text-transform: uppercase;
	font-weight: 700;
	margin: 0;
}

h2 {
	color: var(--basic);
	font-family: 'Open Sans', sans-serif;
	font-size: var(--title-heading);
	text-transform: uppercase;
	font-weight: 800;
	margin: 0;
}

h3 {
	font-family: 'Open Sans', sans-serif;
	font-size: var(--section-heading);
	text-transform: uppercase;
	font-weight: 700;
	margin: 0;
}

h4 {
	font-family: 'Open Sans', sans-serif;
	font-size: var(--subtitle);
	color: var(--basic);
	margin: 0;
}

h5 {
	font-family: 'Open Sans', sans-serif;
	font-size: var(--largest-size);
	font-weight: 400;
	margin: 0;
}

h6 {
	font-family: 'Open Sans', sans-serif;
	font-size: var(--larger-size);
	font-weight: 400;
	margin: 0;
}

p {
	letter-spacing: .5px;
	line-height: 28px;
	margin: 0;
}